import React from 'react';
import ReactDOM from 'react-dom/client';
import { JSONSite } from '@bid/web-shared-ui/src/components/JSONSite';

import './index.scss';

const importModule = async (name: string) => {
  const splitName = name.split('/');

  let module;
  if (splitName.length === 1) {
    module = (await import(`../config/${splitName[0]}.json`));
  }
  if (splitName.length === 2) {
    module = (await import(`../config/${splitName[0]}/${splitName[1]}.json`));
  }

  return module;
};

ReactDOM.createRoot(document.querySelector('.react-root')!).render(
  <React.StrictMode>
    <JSONSite
      importModule={importModule}
    />
  </React.StrictMode>,
);
